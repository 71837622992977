import LogoBlack from "~image/logo/logo-black.png";
import LogoWhite from "~image/logo/logo-white.png";
import counDownImage from "~image/mixed/pattern-img-1.png";
import InnerPromoShape from "~image/mixed/inner-banner-shape.png";
import SiderbarAdsImg from "~image/mixed/ads-img.png";
import commentsUserImg1 from "~image/mixed/user-img-1.png";
import commentsUserImg2 from "~image/mixed/user-img-2.png";
import commentsUserImg3 from "~image/mixed/user-img-3.png";
import CareerPageVideobg from "~image/mixed/career-video-bg.png";
import errorImage from "~image/mixed/404.png";
import workshopXcodeLogo from "~image/workshop/xcode-logo.png"
import workshopSwiftLogo from "~image/workshop/swift-logo.png"
import workshopAppStoreLogo from "~image/workshop/app-store-logo.png"
import workshopHeroImage from "~image/workshop/hero-image.jpg"
import workshopHeroImage3 from "~image/workshop/hero-image-3.jpg"
import workshopHeroImageOverlay from "~image/workshop/hero-bg-overlay.png"
import workshopPromoShape from "~image/workshop/footer-shape.png"


const Images = {
    workshop: {
        workshopXcodeLogo: workshopXcodeLogo,
        workshopSwiftLogo: workshopSwiftLogo,
        workshopAppStoreLogo: workshopAppStoreLogo,
        heroImage: workshopHeroImage,
        heroImage3: workshopHeroImage3,
        heroImgOverlay: workshopHeroImageOverlay,
        ProMoShape: workshopPromoShape,
    },

    pageTitleShape: InnerPromoShape,

    FooterOne: {
        LogoWhite: LogoWhite,
    },
    FooterFourDark: {
        LogoWhite: LogoWhite,
    },
    FooterSix: {
        LogoBlack: LogoBlack,
    },

    SiderbarAdsImg: SiderbarAdsImg,
    CareerPageVideobg: CareerPageVideobg,
    errorImage: errorImage,
    CountdownBgImg: counDownImage

}

export default Images;