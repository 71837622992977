import React from "react"
import { Col, Container, Row } from "react-bootstrap"
// import { Form } from "~components"
import { Images } from "~data"
import Countdown from "./style"

const ComingSoonSection = () => {
  return (
    <Countdown backgroundColor="#f3f4f6">
      <Container className="position-static">
        <Row className="align-items-center justify-content-center position-static">
          <Col xs="12" className="col-xl-6 col-lg-4 position-static">
            <Countdown.Image
              backgroundImage={Images.CountdownBgImg}
            />
          </Col>
          <Col
            xs="12"
            className="col-xl-6 col-lg-8 col-md-10 text-center text-lg-end"
          >
            <Countdown.Box>
              <Countdown.Title as="h2">
                感謝你提供電郵地址，<br/>我們推出新課程的話，<br/>就會立即通知你。
              </Countdown.Title>
              <Countdown.Text as="p">
                 除了工作坊外，我們也推出了一系的 Swift 和 SwiftUI 書籍。<br/>有興趣的話，<a href="https://www.appcoda.com.tw/swift">可以到這裡</a>了解一下。
              </Countdown.Text>
              
            </Countdown.Box>
          </Col>
        </Row>
      </Container>
    </Countdown>
  )
}

export default ComingSoonSection
